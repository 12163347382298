import apiInstance from '../api'
import { isAxiosError } from 'axios'

/*
GET : /c3po/conversations/:conversationId/messages
des : get all messages of a conversation with pagination
type : application/json
*/

interface Message {
  message_id: string
  convo_id: string
  sender_id: string
  body: string
  has_attachments: boolean | null
  status: string
  parent_message_id: string | null
  created_at: string
  attachments?: {
    type: string
    attachment_id?: string
    attachment_url: string
  }[]
}

interface GetMessagesResponse {
  source: string
  data: {
    messages: Message[]
  }
  success: boolean
}

interface ApiError {
  message: string
  statusCode: number
}

export const getMessages = async (
  conversationId: string,
  limit = 10,
  page = 1
): Promise<GetMessagesResponse> => {
  try {
    const response = await apiInstance.get<GetMessagesResponse>(
      `/c3po/conversations/${conversationId}/messages?limit=${limit}&page=${page}`
    )

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    return response.data
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const apiError: ApiError = {
        message: error.response.data.message || 'An error occurred',
        statusCode: error.response.status,
      }
      throw apiError
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}

/*
POST : /c3po/conversations/{{CONVERSATION-ID}}/messages
content-type : application/json
body
{
    "body" : "string"
}

*/

interface SendMessageResponse {
  source: string
  data: {
    message: string
    messageId: string
  }
  success: boolean
}

export const sendMessage = async (
  conversationId: string,
  body: string,
  feMsgId?: string
): Promise<SendMessageResponse> => {
  try {
    const response = await apiInstance.post<SendMessageResponse>(
      `/c3po/conversations/${conversationId}/messages`,
      {
        body,
        feMsgId,
      }
    )

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    return response.data
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const apiError: ApiError = {
        message: error.response.data.message || 'An error occurred',
        statusCode: error.response.status,
      }
      throw apiError
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}

/*
GET: /c3po/conversations/{{CONVERSATION-ID}}/messages/{{MESSAGE-ID}}
des: get a single message
type: application/json

*/

interface IoriginalMessage {
  subject: string
  date: string
  html: string
  email_references?: string[]
  email_from: {
    name: string
    address: string
  }[]
  to_recipients?: {
    name: string
    address: string
  }[]
  cc_recipients?: {
    name: string
    address: string
  }[]
  bcc_recipients?: {
    name: string
    address: string
  }[]
  message_id: string
  in_reply_to?: string
  created_at?: string
  source: string
}

interface GetMessageResponse {
  source: string
  data: {
    message: IoriginalMessage
  }
  success: boolean
}

export const getMessage = async (
  conversationId: string,
  messageId: string
): Promise<GetMessageResponse> => {
  try {
    const response = await apiInstance.get<GetMessageResponse>(
      `/c3po/conversations/${conversationId}/messages/${messageId}`
    )

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    return response.data
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const apiError: ApiError = {
        message: error.response.data.message || 'An error occurred',
        statusCode: error.response.status,
      }
      throw apiError
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}

/*
POST : /c3po/conversations/:convoId/draft/attach
content-type : multipart/form-data
//only files are allowed

res:
{
    "source": "ID10-CHAT-MODULE",
    "data": {
        "message": "Attachment added!",
        "urls": [
            "conversation_attachments/4800be24e868f29d49339d700" (s3key)
        ]
    },
    "success": true
}
*/

interface AttachResponse {
  source: string
  data: {
    message: string
    urls?: string[]
  }
  success: boolean
}

export const attachFile = async (
  conversationId: string,
  files: File[]
): Promise<AttachResponse> => {
  try {
    const formData = new FormData()
    files.forEach((file) => {
      formData.append('attachments', file)
    })

    const response = await apiInstance.post<AttachResponse>(
      `/c3po/conversations/${conversationId}/draft/attach`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    return response.data
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const apiError: ApiError = {
        message: error.response.data.message || 'An error occurred',
        statusCode: error.response.status,
      }
      throw apiError
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}

/*
DELETE : /c3po/conversations/:convoId/draft/attach
content-type : application/json
body : {key: string}
*/

export const deleteAttachment = async (
  conversationId: string,
  key: string
): Promise<AttachResponse> => {
  try {
    const response = await apiInstance.delete<AttachResponse>(
      `/c3po/conversations/${conversationId}/draft/attach`,
      {
        data: { key },
      }
    )

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    return response.data
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const apiError: ApiError = {
        message: error.response.data.message || 'An error occurred',
        statusCode: error.response.status,
      }
      throw apiError
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}

/*
POST: /c3po/conversations/file
BODY: {
key: string
}
des: get signed url for downloading file
type: application/json

*/

interface GetSignedUrlResponse {
  source: string
  data: {
    url: string
  }
  success: boolean
}

export const getSignedUrl = async (
  key: string,
  type?: string
): Promise<GetSignedUrlResponse> => {
  try {
    const response = await apiInstance.post<GetSignedUrlResponse>(
      '/c3po/conversations/file',
      { key, type }
    )

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    return response.data
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const apiError: ApiError = {
        message: error.response.data.message || 'An error occurred',
        statusCode: error.response.status,
      }
      throw apiError
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}

/*
GET : /c3po/conversations/:convo_id/events/:message_id
des : get all events of a message
type : application/json

*/

interface Event {
  event: string
  created_at: string
  email: string
}

interface GetEventsResponse {
  source: string
  data: {
    events: Event[]
  }
  success: boolean
}

export const getEvents = async (
  conversationId: string,
  messageId: string
): Promise<GetEventsResponse> => {
  try {
    const response = await apiInstance.get<GetEventsResponse>(
      `/c3po/conversations/${conversationId}/events/${messageId}`
    )

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    return response.data
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const apiError: ApiError = {
        message: error.response.data.message || 'An error occurred',
        statusCode: error.response.status,
      }
      throw apiError
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}
