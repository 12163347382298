//v6 config

// const awsconfig = {
//   Auth: {
//     Cognito: {
//       userPoolClientId: "vue1sf8r0e7rjspdvec53fb7e",
//       userPoolId: "ap-southeast-1_aw0trygRq",
//       loginWith: {
//         oauth: {
//           domain: "identity.trames-engineering.com",
//           scopes: ["openid email phone profile aws.cognito.signin.user.admin "],
//           redirectSignIn: [`${window.location.origin}/authorize`],
//           redirectSignOut: [`${window.location.origin}/logout`],
//           responseType: "code",
//         }
//       },
//     },
//   },
// };

//v4 config

const isLocalHost = ['localhost']?.includes(window.location.hostname)

const awsconfig = {
  Auth: {
    region: process.env.COGNITO_AWS_REGION,
    userPoolId: process.env.COGNITO_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.COGNITO_AUTH_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    oauth: {
      domain: process.env.COGNITO_AUTH_DOMAIN,
      scope: [
        'openid',
        'email',
        'phone',
        'profile',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: `${window.location.origin}/authorize`,
      redirectSignOut: `${window.location.origin}/logout`,
      responseType: 'code',
    },
    cookieStorage: {
      domain: isLocalHost ? 'localhost' : '.trames.dev',
      path: '/',
      expires: 1,
      sameSite: 'strict',
      secure: !isLocalHost,
    },
  },
}

export default awsconfig
