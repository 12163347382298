/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from 'aws-amplify' //v4
import axios, { isAxiosError } from 'axios'
import { API_BASE_URL } from '../api'
interface AuthTokens {
  idToken: string
  accessToken: string
  refreshToken: string
}

export const awsSignIn = async (
  email: string,
  password: string
): Promise<AuthTokens | undefined> => {
  try {
    const user = await Auth.signIn(email, password)
    if (user) {
      const session = await Auth.currentSession()
      const tokens = {
        idToken: session.getIdToken().getJwtToken(),
        accessToken: session.getAccessToken().getJwtToken(),
        refreshToken: session.getRefreshToken().getToken(),
      }
      return tokens
    }
    throw new Error('User is not signed in')
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const awsSignInWithUserId = async (userId: string) => {
  console.log('userId', userId)
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<AuthTokens | undefined>(async (resolve, reject) => {
    try {
      await Auth.currentAuthenticatedUser()
      const session = await Auth.currentSession()
      const tokens = {
        idToken: session.getIdToken().getJwtToken(),
        accessToken: session.getAccessToken().getJwtToken(),
        refreshToken: session.getRefreshToken().getToken(),
      }
      resolve(tokens)
    } catch (error) {
      console.error('Error getting current authenticated user', error)
      try {
        await Auth.federatedSignIn()
      } catch (signInError) {
        console.error('Error signing in with redirect', signInError)
        reject(new Error(JSON.stringify(signInError)))
      }
    }
  })
}

interface setCookieResponse {
  source: string
  data: {
    message: string
  }
  success: boolean
}

export const setHttpCookie = async (
  authToken: string,
  query: string
): Promise<setCookieResponse> => {
  try {
    const response = await axios.get<setCookieResponse>(
      `${API_BASE_URL}/c3po/conversations/cookie?${query}`,
      {
        headers: {
          Authorization: `${authToken}`,
        },
        withCredentials: true,
      }
    )

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    return response.data
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        throw new Error(`Error: ${error.response.status}`)
      }

      throw new Error(error.message)
    }

    throw new Error('An unexpected error occurred')
  }
}
