import * as React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

export default function AddFrequencyInput({
  frequency,
  setFrequency,
}: {
  frequency: number
  setFrequency: (value: number) => void
}) {
  const availableFrequency = [5, 10, 15, 0]
  return (
    <Box
      sx={{
        width: 600,
        '&:focus': {
          outline: 'none',
        },
        outline: 'none',
        boxShadow: 'none',
      }}
      id='frequency'
    >
      <FormControl component='fieldset'>
        <RadioGroup
          row
          aria-labelledby='frequency'
          name='frequency'
          value={frequency}
          onChange={(e) => setFrequency(parseInt(e.target.value))}
          sx={{ width: '100%' }}
        >
          <List
            component='nav'
            sx={{
              display: 'flex',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              padding: 0,
              width: 500,
            }}
          >
            {availableFrequency.map((value, index) => (
              <React.Fragment key={value}>
                {index !== 0 && <Divider />}
                <ListItem sx={{ padding: 0 }}>
                  <FormControlLabel
                    value={`${value}`}
                    control={<Radio />}
                    label={value === 0 ? 'Once' : `${value} sec`}
                    sx={{ margin: 0, width: '100%', padding: 1 }}
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </RadioGroup>
      </FormControl>
    </Box>
  )
}
