/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react'
import './Login.css'
import { Auth } from 'aws-amplify'
import { useLocation } from 'react-router-dom'
// import CheckTokenAndRedirect from '../../components/CheckTokenAndRedirect'
// import { Button } from '@mui/material'

const Login = () => {
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const userId = queryParams.get('user')
    if (userId) {
      handleSSOLogin(userId)
    } else {
      handleCognitoLogin()
    }
  }, [location])

  const handleSSOLogin = async (userId: string) => {
    try {
      console.log('Logging in with SSO...', userId)
      await Auth.federatedSignIn()
    } catch (error: any) {
      console.error('Error logging in', error)
    }
  }

  const handleCognitoLogin = async () => {
    try {
      await Auth.federatedSignIn()
    } catch (error: any) {
      console.error('Error logging in', error)
    }
  }

  // return (
  //   <>
  //     <CheckTokenAndRedirect />
  //     <div className='login-container'>
  //       <div className='login-box'>
  //         <h2 className='login-heading'>Login</h2>
  //         <Button
  //           variant='contained'
  //           onClick={handleCognitoLogin}
  //           style={{
  //             fontSize: '12px'
  //           }}
  //           color='primary'
  //         >
  //           Login with Cognito
  //         </Button>
  //       </div>
  //     </div>
  //   </>
  // )

  return <div></div>
}

export default Login
