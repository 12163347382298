import { useEffect } from 'react'
import { Auth } from 'aws-amplify'

const Logout: React.FC = () => {
  useEffect(() => {
    const doSignout = async () => {
      delete localStorage['userEmail']
      try {
        const res = await Auth.signOut()
        if (res === undefined) {
          window.location.href = '/login'
        }
      } catch (e) {
        window.location.href = '/login'
      }
    }
    doSignout()
  }, [])

  //loading until the redirect happens
  return <div>Logging out...</div>
}

export default Logout
