import React from 'react'
import { Navigate } from 'react-router-dom'
import useCognitoCookieValue from '../hooks/useDynamicCookieMonitor'

const ProtectedRoute: React.FC<{ element: JSX.Element }> = ({ element }) => {
  const authenticated = useCognitoCookieValue('accessToken')
  return authenticated ? element : <Navigate to='/login' replace />
}

export default ProtectedRoute
