import React, { useEffect, useState } from 'react'
import {
  Dialog,
  IconButton,
  Typography,
  Box,
  CircularProgress,
  Alert,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import { getMessage } from '../services/messages' // Replace with your actual API service

const DataBox = styled(Box)(({ theme }) => ({
  width: '600px',
  maxWidth: '600px',
  maxHeight: '90vh', // Make the box scrollable
  overflowY: 'auto', // Add vertical scroll
  padding: theme.spacing(2),
  borderRadius: 0,
  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
  outline: 'none',
  position: 'relative',
}))

interface DataDialogProps {
  emailMessageId: string | null
  open: boolean
  onClose: () => void
}

interface ApiResponse {
  subject: string
  date: string
  html: string
  email_from: { name: string; address: string }[]
  to_recipients?: { name: string; address: string }[]
  cc_recipients?: { name: string; address: string }[]
  bcc_recipients?: { name: string; address: string }[]
  source: string
}

const DataDialog: React.FC<DataDialogProps> = ({
  emailMessageId,
  open,
  onClose,
}) => {
  const [data, setData] = useState<ApiResponse | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const { convoId } = useParams()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (open && convoId && emailMessageId) {
      setLoading(true)
      setError(null)
      getMessage(convoId, emailMessageId)
        .then((response) => {
          if (response.success) {
            setData(response.data.message)
          } else {
            setError('Failed to load data')
          }
        })
        .catch((err) => {
          setError(err.message)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
      setError('No data available')
      setData(null)
    }
  }, [open, convoId, emailMessageId])

  if (!open) return null

  const content = (
    <>
      <Typography variant='h6' gutterBottom>
        Original Message
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          style={{ outline: 'none', boxShadow: 'none' }}
        >
          <CloseIcon sx={{ outline: 'none', boxShadow: 'none' }} />
        </IconButton>
      </Typography>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity='error'>{error}</Alert>
      ) : data ? (
        <>
          <Typography variant='h6' gutterBottom>
            <strong>Subject:</strong> {data.subject}
          </Typography>
          <Typography gutterBottom>
            <strong>Date:</strong> {new Date(data.date).toLocaleString()}
          </Typography>
          <Typography gutterBottom>
            <strong>From:</strong>{' '}
            {data.email_from.map((e) => e.name).join(', ')}
          </Typography>
          <Typography gutterBottom>
            <strong>To:</strong>{' '}
            {data.to_recipients?.map((e) => e.address).join(', ')}
          </Typography>
          <Typography gutterBottom>
            <strong>CC:</strong>{' '}
            {data.cc_recipients?.map((e) => e.address).join(', ')}
          </Typography>
          <Typography gutterBottom>
            <strong>BCC:</strong>{' '}
            {data.bcc_recipients?.map((e) => e.address).join(', ')}
          </Typography>
          <Typography gutterBottom>
            <strong>Source:</strong> {data.source}
          </Typography>
          <div
            dangerouslySetInnerHTML={{ __html: data.html }}
            style={{ marginTop: '20px', overflowY: 'auto' }}
          />
        </>
      ) : (
        <Alert severity='info'>No information available</Alert>
      )}
    </>
  )

  return isMobile ? (
    <Dialog open={open} onClose={onClose} fullScreen>
      {content}
    </Dialog>
  ) : (
    <DataBox>{content}</DataBox>
  )
}

export default DataDialog
