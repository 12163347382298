/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect, useState, MouseEvent } from 'react'
import {
  Box,
  Typography,
  Paper,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import DownArrow from '@mui/icons-material/KeyboardArrowDown'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DownloadIcon from '@mui/icons-material/Download'
import FileIcon from '@mui/icons-material/InsertDriveFile'
import ClockIcon from '@mui/icons-material/AccessTime'
import SentIcon from '@mui/icons-material/Check'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { getSignedUrl } from '../services/messages' //api call

interface Message {
  message_id: string
  convo_id: string
  sender_id: string
  body: string
  has_attachments: boolean | null
  status: string
  parent_message_id: string | null
  created_at: string
  attachments?: {
    type: string
    attachment_id?: string
    attachment_url: string
    filename?: string
    size?: number
  }[]
  email_message_id?: string | undefined | null
  has_preview?: boolean
}
interface Participant {
  participant_value: string
  participant_channel: string
  participant_id: string
}
interface MessageListProps {
  messages: Message[]
  participants: Participant[]
  selectedConvo: string
  setSelectedMessage: (messageId: string) => void
  selectedMessageId: string | null
  emailMessageId: string | null
  setSelectedMessageId: (messageId: string) => void
  setopenMessageEvents: (open: boolean) => void
  setopenViewMessage: (open: boolean) => void
  setEmailMessageId: (emailMessageId: string) => void
  onScroll: (e: React.UIEvent<HTMLElement>) => void
}

interface MessageBubbleProps {
  own: boolean
}

const MessageBubble = styled(Paper)<MessageBubbleProps>(({ theme, own }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  alignSelf: own ? 'flex-end' : 'flex-start',
  backgroundColor: own
    ? theme.palette.mode === 'dark'
      ? theme.palette.primary.dark
      : theme.palette.primary.light
    : theme.palette.mode === 'dark'
    ? theme.palette.grey[800]
    : theme.palette.grey[200],
  color: own
    ? theme.palette.getContrastText(
        theme.palette.mode === 'dark'
          ? theme.palette.primary.dark
          : theme.palette.primary.light
      )
    : theme.palette.getContrastText(
        theme.palette.mode === 'dark'
          ? theme.palette.grey[800]
          : theme.palette.grey[200]
      ),
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  position: 'relative',
  '&:hover .hover-icon': {
    visibility: 'visible',
  },
}))

const MessageList: React.FC<MessageListProps> = ({
  messages,
  participants,
  selectedConvo,
  selectedMessageId,
  setSelectedMessageId,
  setSelectedMessage,
  setopenMessageEvents,
  setopenViewMessage,
  setEmailMessageId,
  emailMessageId,
  onScroll,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))
  const [userEmail] = useLocalStorage('userEmail', '')
  const bottomRef = useRef<HTMLDivElement | null>(null)
  const listRef = useRef<HTMLDivElement | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [uploading, setUploading] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => {
      setLoading(false)
    }, 1000)
    return () => clearTimeout(timer)
  }, [selectedConvo])

  const authorId = participants.find(
    (participant) => participant.participant_value === userEmail
  )?.participant_id

  const handleMenuClick = (
    event: MouseEvent<HTMLElement>,
    message: Message
  ) => {
    setAnchorEl(event.currentTarget)
    setSelectedMessageId(message.message_id)
    setEmailMessageId(message?.email_message_id || '')
    setSelectedMessage(message.body)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const onEventsClicked = (messageId: string) => {
    console.log(`Displaying mail event for message: ${messageId}`)
    setSelectedMessageId(selectedMessageId || '')
    setopenMessageEvents(true)
    handleMenuClose()
  }

  const onViewOriginalClicked = (messageId: string) => {
    console.log(`Viewing original message: ${messageId}`)
    setSelectedMessageId(messageId || '')
    setEmailMessageId(messageId || '')
    setopenViewMessage(true)
    handleMenuClose()
  }

  const handleDownloadAttachment = async (
    attachmentUrl: string,
    attachmentId: string
  ) => {
    console.log(`Downloading attachment: ${attachmentUrl}`)
    setUploading(attachmentId)
    setError(null)
    try {
      const { data } = await getSignedUrl(attachmentUrl)
      window.open(data.url, '_self')?.focus()
      console.log('Downloaded attachment successfully', data.url)
    } catch (error) {
      setError('Failed to download attachment')
      console.error('Failed to get signed URL', error)
    } finally {
      setUploading(null)
    }
  }

  const handleViewAttachment = async (attachmentUrl: string, type?: string) => {
    console.log(`Viewing attachment: ${attachmentUrl}`)
    setError(null)
    try {
      const { data } = await getSignedUrl(attachmentUrl, type)
      window.open(data.url, '_blank')?.focus()
      console.log('Viewed attachment successfully', data.url)
    } catch (error) {
      setError('Failed to view attachment')
      console.error('Failed to get signed URL', error)
    }
  }

  const getParticipantName = (senderId: string) => {
    const participant = participants.find((p) => p.participant_id === senderId)
    if (participant) {
      if (participant.participant_channel === 'email') {
        return participant.participant_value.split('@')[0]
      }
      return participant.participant_value
    }
    return 'Unknown'
  }

  useEffect(() => {
    const listNode = listRef.current
    if (listNode && bottomRef.current) {
      const isAtBottom =
        listNode.scrollHeight - listNode.scrollTop === listNode.clientHeight
      if (isAtBottom) {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }

    // set scoll to last message/node
    if (listNode) {
      listNode.scrollTop = listNode.scrollHeight
    }
  }, [messages, loading])

  if (loading) {
    return (
      <Box
        sx={{
          overflowY: 'auto',
          maxHeight: isMobile ? '400px' : '600px',
          maxWidth: 'auto',
          height: '600px',
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Box
        ref={listRef}
        sx={{
          overflowY: 'auto',
          maxHeight: isMobile ? '400px' : '600px',
          maxWidth: 'auto',
          // height: '600px',
          height: '100%',
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          p: 2,
          '&::-webkit-scrollbar': {
            width: '0.4em',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'lightgrey',
          },
          '&::-webkit-scrollbar-button': {
            display: 'none',
          },
        }}
        onScroll={onScroll}
      >
        {messages.map((message, index) => {
          const ownMessage = message.sender_id === authorId
          return (
            <MessageBubble key={index} own={ownMessage}>
              <Typography
                variant='body2'
                sx={{ fontWeight: 'bold', marginBottom: '4px' }}
              >
                {getParticipantName(message.sender_id)}
              </Typography>
              <IconButton
                className='hover-icon'
                size='small'
                sx={{
                  position: 'absolute',
                  top: 4,
                  right: 4,
                  visibility: 'hidden',
                }}
                style={{ outline: 'none', boxShadow: 'none' }}
                onClick={(e) => handleMenuClick(e, message)}
              >
                <DownArrow fontSize='small' />
              </IconButton>

              <Typography
                variant='body2'
                sx={{
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  lineHeight: '1.5',
                  fontSize: '1rem',
                  fontWeight: 500,
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: message.body }}
                  style={{ wordWrap: 'break-word' }}
                ></div>
              </Typography>
              {typeof message.has_preview === 'boolean' &&
                !message.has_preview && (
                  <Typography
                    variant='caption'
                    sx={{
                      textAlign: ownMessage ? 'right' : 'left',
                      fontSize: '0.65rem',
                      color: 'blue',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={() =>
                      onViewOriginalClicked(message?.email_message_id || '')
                    }
                  >
                    View Original
                  </Typography>
                )}
              {message.has_attachments &&
                message.attachments?.map((attachment) => (
                  <Box
                    key={attachment.attachment_id}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: 1,
                      padding: '4px',
                      border: '1px solid lightgrey',
                      borderRadius: '2px',
                      backgroundColor:
                        theme.palette.mode === 'dark' ? '#333' : '#f9f9f9',
                      boxShadow: 'none',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <FileIcon fontSize='small' sx={{ marginRight: '4px' }} />
                      <Typography variant='caption'>
                        {attachment?.filename || 'Attachment'}{' '}
                        {attachment?.size &&
                          `(${(attachment.size / 1024).toFixed(2)} KB)`}{' '}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton
                        size='small'
                        style={{ outline: 'none', boxShadow: 'none' }}
                        onClick={(e) => {
                          e.preventDefault()
                          handleViewAttachment(
                            attachment.attachment_url,
                            attachment.type
                          )
                        }}
                      >
                        <VisibilityIcon fontSize='small' />
                      </IconButton>
                      <IconButton
                        size='small'
                        style={{ outline: 'none', boxShadow: 'none' }}
                        onClick={(e) => {
                          e.preventDefault()
                          handleDownloadAttachment(
                            attachment.attachment_url,
                            `${attachment.attachment_url}_${message.message_id}`
                          )
                        }}
                      >
                        {uploading ===
                        `${attachment.attachment_url}_${message.message_id}` ? (
                          <CircularProgress size={24} />
                        ) : (
                          <DownloadIcon fontSize='small' />
                        )}
                      </IconButton>
                    </Box>
                  </Box>
                ))}

              <Typography
                variant='caption'
                display='block'
                sx={{
                  textAlign: ownMessage ? 'right' : 'left',
                  fontSize: '0.65rem',
                }}
              >
                <Box
                  component='span'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {new Date(message.created_at).toLocaleString()}
                  {ownMessage && (
                    <>
                      {message.status === 'pending' && (
                        <ClockIcon fontSize='small' sx={{ marginLeft: 1 }} />
                      )}
                      {message.status === 'sent' && (
                        <SentIcon fontSize='small' sx={{ marginLeft: 1 }} />
                      )}
                    </>
                  )}
                </Box>
              </Typography>
              {index === messages.length - 1 && <div ref={bottomRef} />}
            </MessageBubble>
          )
        })}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{
            '& .MuiPaper-root': {
              minWidth: '100px',
              padding: '0px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              borderRadius: '0px',
            },
            '& .MuiMenuItem-root': {
              margin: '0px 0',
              fontSize: '0.875rem',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
              },
            },
          }}
        >
          <MenuItem onClick={() => onEventsClicked(selectedMessageId || '')}>
            Message Events
          </MenuItem>
          <MenuItem onClick={() => onViewOriginalClicked(emailMessageId || '')}>
            View Original
          </MenuItem>
          {/* Add more MenuItems here if needed */}
        </Menu>
        {error && (
          <Typography color='error' variant='body2'>
            {error}
          </Typography>
        )}
      </Box>
    </>
  )
}

export default MessageList
