import React, { useMemo } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import { ThemeProvider, CssBaseline } from '@mui/material'
import { lightTheme, darkTheme } from './theme/index'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useLocalStorage } from './hooks/useLocalStorage'

const Main = () => {
  const systemTheme = useMediaQuery('(prefers-color-scheme: dark)')
    ? true
    : false
  const [isDarkMode] = useLocalStorage('isDarkMode', systemTheme)

  // Memoized theme to avoid unnecessary re-renders
  const theme = useMemo(
    () => (isDarkMode ? darkTheme : lightTheme),
    [isDarkMode]
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  )
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
)
