import React, { ReactNode } from 'react'
import { Box } from '@mui/material'
import Drawer from './Drawer'
import Appbar from './Appbar'

interface LayoutProps {
  children: ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Appbar />
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Drawer />
        <Box component='main' sx={{ flexGrow: 1, overflow: 'auto' }}>
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default Layout
