import React, { useEffect, useState } from 'react'
import {
  Dialog,
  IconButton,
  Typography,
  Box,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
  getConversation,
  addRemoveParticipants,
} from '../../services/conversation'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import AddParticipantButton from './AddParticipants'
import AddParticipantDialog from './AddParticpantsDialog'

const ConversationBox = styled(Box)(({ theme }) => ({
  width: '500px',
  maxWidth: '500px',
  maxHeight: '90vh', // Make the box scrollable
  overflowY: 'auto', // Add vertical scroll
  padding: theme.spacing(2),
  borderRadius: 0,
  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
  outline: 'none',
  position: 'relative',
}))

interface ConvoInfoDialogProps {
  open: boolean
  onClose: () => void
}

interface Participant {
  participant_value: string
  participant_channel: string
}

interface Conversation {
  convo_id: string
  title: string
  created_at?: string
  participants: Participant[]
  participant_count?: string
  is_auto_generated: boolean
  email_message_id?: string
}

const ConvoInfoDialog: React.FC<ConvoInfoDialogProps> = ({ open, onClose }) => {
  const [conversation, setConversation] = useState<Conversation | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [userEmail] = useLocalStorage('userEmail', '')
  const [addParticipant, setAddParticipant] = useState(false)
  const { convoId } = useParams()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (open && convoId) {
      setLoading(true)
      setError(null)
      getConversation(convoId)
        .then((response) => {
          if (response.success) {
            setConversation(response?.data?.conversation)
          } else {
            setError('Failed to load conversation data')
          }
        })
        .catch((err) => {
          setError(err.message)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [open, convoId])

  if (!open) return null

  const onRemoveParticipant = (participant: Participant) => {
    const data = {
      action: 'remove',
      participants: [
        {
          channel: participant.participant_channel,
          value: participant.participant_value,
        },
      ],
    }
    if (!convoId) {
      return
    }
    addRemoveParticipants(convoId, data)
      .then((response) => {
        if (response.success) {
          setConversation((prev) => {
            if (!prev) return prev
            const newParticipants = prev.participants.filter(
              (p) => p.participant_value !== participant.participant_value
            )
            return {
              ...prev,
              participants: newParticipants,
              participant_count: String(newParticipants.length),
            }
          })
        } else {
          setError('Failed to remove participant')
        }
      })
      .catch((err) => {
        setError(err.message)
      })
  }

  const onAddParticipant = (channel: string, value: string) => {
    const data = {
      action: 'add',
      participants: [{ channel, value }],
    }
    if (!convoId) {
      return
    }
    addRemoveParticipants(convoId, data)
      .then((response) => {
        if (response.success) {
          setConversation((prev) => {
            if (!prev) return prev
            const newParticipants = [
              ...prev.participants,
              { participant_channel: channel, participant_value: value },
            ]
            return {
              ...prev,
              participants: newParticipants,
              participant_count: String(newParticipants.length),
            }
          })
        } else {
          setError('Failed to add participant')
        }
      })
      .catch((err) => {
        setError(err.message)
      })
  }

  const content = (
    <>
      <Typography variant='h6' gutterBottom>
        Conversation Info
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          style={{ outline: 'none', boxShadow: 'none' }}
        >
          <CloseIcon sx={{ outline: 'none', boxShadow: 'none' }} />
        </IconButton>
      </Typography>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity='error'>{error}</Alert>
      ) : conversation ? (
        <>
          <Typography variant='h6' gutterBottom>
            {conversation.title}
          </Typography>
          <Typography gutterBottom>
            <strong>Created At:</strong>{' '}
            {conversation.created_at
              ? new Date(conversation.created_at).toLocaleString()
              : 'N/A'}
          </Typography>
          <Typography gutterBottom>
            <strong>Total Participants:</strong>{' '}
            {conversation.participant_count}
          </Typography>
          <AddParticipantButton
            handleAddClick={() => setAddParticipant(true)}
            isAutoGenerated={conversation.is_auto_generated}
          />
          <AddParticipantDialog
            open={addParticipant}
            onClose={() => setAddParticipant(false)}
            onAddParticipant={onAddParticipant}
          />
          <List style={{ marginTop: '20px' }}>
            <Divider />
            {conversation.participants?.map((participant, index) => (
              <ListItem
                key={index}
                sx={{ border: '1px solid #f0f0f0', borderRadius: '5px' }}
                secondaryAction={
                  <IconButton
                    edge='end'
                    aria-label='delete'
                    style={{ outline: 'none', boxShadow: 'none' }}
                    disabled={
                      participant.participant_value === userEmail ||
                      conversation.is_auto_generated
                    }
                    onClick={() => onRemoveParticipant(participant)}
                  >
                    <RemoveIcon />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={participant.participant_value}
                  secondary={
                    participant.participant_channel === 'email'
                      ? 'Email'
                      : 'Group'
                  }
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                />
              </ListItem>
            ))}
          </List>
        </>
      ) : (
        <Alert severity='info'>No information available</Alert>
      )}
    </>
  )

  return isMobile ? (
    <Dialog open={open} onClose={onClose} fullScreen>
      {content}
    </Dialog>
  ) : (
    <ConversationBox>{content}</ConversationBox>
  )
}

export default ConvoInfoDialog
