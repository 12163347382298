import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Stack,
  Chip,
  DialogActions,
  Button,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'

interface AddParticipantDialogProps {
  open: boolean
  onClose: () => void
  onAddParticipant: (channel: string, value: string) => void
}

const AddParticipantDialog: React.FC<AddParticipantDialogProps> = ({
  open,
  onClose,
  onAddParticipant,
}) => {
  const [channel, setChannel] = useState<string>('')
  const [value, setValue] = useState<string>('')
  const [emailError, setEmailError] = useState<string | null>(null)
  const participantChannels = ['email']
  const [participants, setParticipants] = useState<
    { channel: string; value: string }[]
  >([])

  const handleRemoveParticipant = (index: number) => {
    setParticipants((prev) => prev.filter((_, i) => i !== index))
  }

  const handleAddParticipant = () => {
    if (channel === 'email' && !value.includes('@')) {
      setEmailError('Please enter a valid email address')
      return
    }
    setEmailError(null)
    if (
      !participants.some(
        (participant) =>
          participant.channel === channel && participant.value === value
      )
    ) {
      setParticipants([...participants, { channel, value }])
    }
    setChannel('')
    setValue('')
  }

  const handleAddClick = () => {
    participants.forEach((participant) => {
      onAddParticipant(participant.channel, participant.value)
    })
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant='h6' gutterBottom>
          Add Participant
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
          <FormControl fullWidth variant='outlined'>
            <InputLabel>Type</InputLabel>
            <Select
              label='Channel'
              value={channel}
              onChange={(e) => setChannel(e.target.value)}
              required
            >
              {participantChannels.map((channel) => (
                <MenuItem key={channel} value={channel}>
                  {channel}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin='dense'
            label='Value'
            type='text'
            fullWidth
            variant='outlined'
            value={value}
            onChange={(e) => setValue(e.target.value)}
            required
            error={!!emailError}
            helperText={emailError}
          />
          <IconButton
            color='primary'
            onClick={handleAddParticipant}
            disabled={!channel || !value}
          >
            <AddCircleIcon />
          </IconButton>
        </Box>
        <Stack direction='row' spacing={1} sx={{ mt: 2, flexWrap: 'wrap' }}>
          {participants.map((participant, index) => (
            <Chip
              key={index}
              label={`${participant.channel}: ${participant.value}`}
              onDelete={() => handleRemoveParticipant(index)}
              deleteIcon={<DeleteIcon />}
              sx={{ margin: '4px' }}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddClick} color='primary'>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddParticipantDialog
