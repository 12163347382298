/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  Stack,
  CircularProgress,
  Alert,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import { useState } from 'react'
import { createConversation } from '../services/conversation'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { useNavigate } from 'react-router-dom'

const participantChannels = ['email', 'organization']

export const ParticipantInput = ({
  channel,
  setChannel,
  value,
  setValue,
  handleAddParticipant,
}: any) => {
  const [emailError, setEmailError] = useState<string | null>(null)

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleAddClick = () => {
    if (channel === 'email' && !validateEmail(value)) {
      setEmailError('Please enter a valid email address')
      return
    }
    setEmailError(null)
    handleAddParticipant()
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
      <FormControl fullWidth variant='outlined'>
        <InputLabel>Type</InputLabel>
        <Select
          label='Channel'
          value={channel}
          onChange={(e) => setChannel(e.target.value)}
          required
        >
          {participantChannels.map((channel) => (
            <MenuItem key={channel} value={channel}>
              {channel}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        margin='dense'
        label='Value'
        type='text'
        fullWidth
        variant='outlined'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        required
        error={!!emailError}
        helperText={emailError}
      />
      <IconButton
        color='primary'
        onClick={handleAddClick}
        disabled={!channel || !value}
      >
        <AddCircleIcon />
      </IconButton>
    </Box>
  )
}

export default function CreateConvoDialog({ open, onClose }: any) {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [channel, setChannel] = useState('')
  const [value, setValue] = useState('')
  const [participants, setParticipants] = useState<
    { channel: string; value: string }[]
  >([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [userEmail] = useLocalStorage('userEmail', '')

  const handleAddParticipant = () => {
    if (channel && value) {
      if (channel === 'email' && value === userEmail) {
        setError('You cannot add yourself as a participant')
        return
      }
      const newParticipants = participants.filter(
        (participant) =>
          participant.channel !== channel || participant.value !== value
      )
      if (channel === 'organization') {
        setParticipants([...newParticipants, { channel: 'group_id', value }])
      } else {
        setParticipants([...newParticipants, { channel, value }])
      }
      setChannel('')
      setValue('')
    }
  }

  const handleRemoveParticipant = (index: number) => {
    const newParticipants = participants.filter((_, i) => i !== index)
    setParticipants(newParticipants)
  }

  const handleCreateClick = async () => {
    if (!name.trim()) {
      setError('Please enter a conversation name')
      return
    }

    if (participants.length === 0) {
      setError('Please add at least one participant')
      return
    }

    setLoading(true)
    setError(null)

    const formJson = {
      name,
      participants,
    }

    try {
      const { data } = await createConversation(formJson)

      const convoId = data?.conversationId
      onClose()
      navigate(`/conversations/${convoId}`)
    } catch (error: any) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: '600px' }, // Fixed width
      }}
    >
      <DialogTitle>Create a new conversation</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <TextField
          margin='dense'
          label='Conversation Name'
          type='text'
          fullWidth
          variant='outlined'
          value={name}
          onChange={(e) => setName(e.target.value)}
          name='name'
          inputProps={{ maxLength: 50 }}
          required
        />
        <ParticipantInput
          channel={channel}
          setChannel={setChannel}
          value={value}
          setValue={setValue}
          handleAddParticipant={handleAddParticipant}
        />
        <Stack direction='row' spacing={1} sx={{ mt: 2, flexWrap: 'wrap' }}>
          {participants.map((participant, index) => (
            <Chip
              key={index}
              label={`${participant.channel}: ${participant.value}`}
              onDelete={() => handleRemoveParticipant(index)}
              deleteIcon={<DeleteIcon />}
              sx={{ margin: '4px' }}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          style={{ outline: 'none', boxShadow: 'none' }}
        >
          Cancel
        </Button>
        <Button
          style={{ outline: 'none', boxShadow: 'none' }}
          disabled={loading}
          onClick={handleCreateClick}
        >
          {loading ? <CircularProgress size={24} /> : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
