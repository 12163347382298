import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Container,
  Divider,
} from '@mui/material'
import AddFrequencyInput from '../components/AddFrequency'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import useCognitoCookieValue from '../hooks/useDynamicCookieMonitor'

export default function Settings() {
  const navigate = useNavigate()
  const theme = useTheme()
  const [userEmail, setUserEmail] = useLocalStorage('userEmail', '')
  const [frequency, setFrequency] = useLocalStorage('frequency', 0)
  const [error, setError] = useState<string | null>(null)
  const [isDarkMode, setDarkMode] = useLocalStorage('isDarkMode', false)

  const token = useCognitoCookieValue('accessToken')

  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.default
  }, [theme.palette.mode, theme.palette.background.default])

  const handleResetToken = async () => {
    try {
      setUserEmail('')
      setFrequency(0)
      setError(null)
    } catch (error) {
      setError((error as Error).message)
    }
  }

  const onLogout = async () => {
    localStorage.removeItem('userEmail')
    try {
      await Auth.signOut()
      window.location.href = '/logout'
    } catch (e) {
      window.location.href = '/logout'
    }
  }

  const toggleDarkMode = () => {
    console.log('toggleDarkMode')
    setDarkMode(!isDarkMode)
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 64, // Height of the app bar
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: 1300,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Container maxWidth='sm' sx={{ py: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            mb: 4,
          }}
        >
          <Typography variant='h4' component='h1'>
            Settings
          </Typography>
          <Box>
            <Button onClick={() => navigate('/')} color='primary'>
              Close
            </Button>
          </Box>
        </Box>

        <Box component='section' sx={{ mb: 4, width: '100%' }}>
          <Typography variant='h6' component='h2' gutterBottom>
            Token
          </Typography>
          <TextField
            fullWidth
            label='Enter token'
            value={token}
            disabled
            margin='normal'
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button variant='contained' color='primary' disabled>
              Validate & Save
            </Button>
            <Button
              variant='outlined'
              color='secondary'
              onClick={handleResetToken}
              disabled
            >
              Reset token
            </Button>
          </Box>
        </Box>

        <Divider sx={{ my: 4 }} />

        <Box component='section' sx={{ mb: 4, width: '100%' }}>
          <Typography variant='h6' component='h2' gutterBottom>
            Message fetch frequency
          </Typography>
          <AddFrequencyInput
            frequency={frequency}
            setFrequency={setFrequency}
          />
        </Box>

        {userEmail && (
          <>
            <Divider sx={{ my: 4 }} />
            <Box component='section' sx={{ mb: 4, width: '100%' }}>
              <Typography variant='h6' component='h2' gutterBottom>
                Token Data
              </Typography>
              <Typography>
                Email: <strong>{userEmail}</strong>
              </Typography>
            </Box>
          </>
        )}

        <Box
          sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: 4 }}
        >
          <Button
            variant='contained'
            color='secondary'
            onClick={onLogout}
            sx={{ flexGrow: 1, flexBasis: '80%' }}
          >
            Logout
          </Button>

          <Button
            onClick={toggleDarkMode}
            color='inherit'
            sx={{
              flexGrow: 0,
              flexBasis: '15%',
              ml: 2,
              outline: '1px solid',
              outlineColor: theme.palette.divider,
              boxShadow: 'none',
              '&:focus': {
                outline: 'none',
              },
            }}
          >
            {isDarkMode ? 'Light' : 'Dark'}
          </Button>
        </Box>

        {error && (
          <Typography color='error' sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </Container>
    </Box>
  )
}
