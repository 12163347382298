import React from 'react'
import { ButtonBase, Box, IconButton, Typography } from '@mui/material'
import GroupAddIcon from '@mui/icons-material/GroupAdd'

const AddParticipantButton: React.FC<{
  handleAddClick: () => void
  isAutoGenerated: boolean
}> = ({ handleAddClick, isAutoGenerated }) => {
  return (
    <ButtonBase
      onClick={handleAddClick}
      style={{
        marginTop: 2,
        width: 'fit-content',
        outline: 'none',
        boxShadow: 'none',
      }}
      disabled={isAutoGenerated}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          p: 1,
          borderRadius: 1,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <IconButton
          color='primary'
          style={{ outline: 'none', boxShadow: 'none' }}
        >
          <GroupAddIcon />
        </IconButton>
        <Typography variant='h6' gutterBottom sx={{ mt: 1 }}>
          Add Participant
        </Typography>
      </Box>
    </ButtonBase>
  )
}

export default AddParticipantButton
