import { useEffect, useState, useRef, useCallback } from 'react'
import Cookies from 'js-cookie'

const useCognitoCookieValue = (
  valueKey: 'accessToken' | 'idToken' | 'refreshToken' | 'LastAuthUser',
  checkInterval: number = 500
): string | null => {
  const webClientId = process.env.COGNITO_AUTH_USER_POOL_WEB_CLIENT_ID

  const getCookieValue = useCallback((): string | null => {
    let cookieName: string
    if (valueKey === 'LastAuthUser') {
      cookieName = `CognitoIdentityServiceProvider.${webClientId}.LastAuthUser`
    } else {
      const userName = Cookies.get(
        `CognitoIdentityServiceProvider.${webClientId}.LastAuthUser`
      )
      if (!userName) return null
      cookieName = `CognitoIdentityServiceProvider.${webClientId}.${userName}.${valueKey}`
    }
    return Cookies.get(cookieName) || null
  }, [valueKey, webClientId])

  const [cookieValue, setCookieValue] = useState<string | null>(() =>
    getCookieValue()
  )
  const previousValue = useRef<string | null>(cookieValue)

  useEffect(() => {
    const checkCookieChange = () => {
      const currentValue = getCookieValue()
      if (currentValue !== previousValue.current) {
        previousValue.current = currentValue
        setCookieValue(currentValue)
      }
    }

    const intervalId = setInterval(checkCookieChange, checkInterval)
    return () => clearInterval(intervalId)
  }, [getCookieValue, checkInterval])

  return cookieValue
}

export default useCognitoCookieValue
