import axios from 'axios'
import Cookies from 'js-cookie'

// export const API_BASE_URL = 'https://id-10-server-dev.azurewebsites.net/id10/'
export const API_BASE_URL = 'http://localhost:8080/id10/'

const getToken = () => {
  const webClientId = process.env.COGNITO_AUTH_USER_POOL_WEB_CLIENT_ID
  const userName = Cookies.get(
    `CognitoIdentityServiceProvider.${webClientId}.LastAuthUser`
  )
  if (!userName) return null
  const token = Cookies.get(
    `CognitoIdentityServiceProvider.${webClientId}.${userName}.accessToken`
  )
  return token
}

const apiInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

apiInstance.interceptors.request.use(
  (config) => {
    const token = getToken()
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Add a response interceptor
apiInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx causes this function to trigger
    if (error.response && error.response.status === 401) {
      // window.location.href = "/login"; // Redirect to the settings page
      console.log('401 error')
    } else if (error.response && error.response.status === 404) {
      // window.location.href = "/";
      console.log('404 error')
    }
    return Promise.reject(error)
  }
)

export default apiInstance
