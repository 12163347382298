import { createTheme } from '@mui/material/styles'

const commonPalette = {
  primary: {
    main: '#3f51b5', // Blue - commonly used for primary actions
  },
  secondary: {
    main: '#f50057', // Pink
  },
  error: {
    main: '#F44336', // Red - for errors
  },
  warning: {
    main: '#FFC107', // Yellow - for warnings
  },
  info: {
    main: '#2196F3', // Blue - for informational messages
  },
  success: {
    main: '#4CAF50', // Green - success states
  },
}

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#F5F5F5', // Light grey background
      paper: '#FFFFFF', // White chat area
    },
    text: {
      primary: '#212121', // Dark text for light mode
      secondary: '#757575', // Grey text for light mode
    },
    divider: '#BDBDBD', // Light grey dividers
    ...commonPalette,
  },
  typography: {
    fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif`,
    fontSize: 14,
  },
  shape: {
    borderRadius: 8, // Slightly rounded corners for a modern look
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Disable uppercase text transformation
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '8px 0', // Consistent spacing for inputs
        },
      },
    },
  },
})

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212', // Very dark grey background
      paper: '#1E1E1E', // Darker grey chat area
    },
    text: {
      primary: '#FFFFFF', // White text for dark mode
      secondary: '#B0B0B0', // Light grey text for dark mode
    },
    divider: '#424242', // Dark grey dividers
    ...commonPalette,
  },
  typography: {
    fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif`,
    fontSize: 14,
  },
  shape: {
    borderRadius: 8, // Consistent with light theme
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '8px 0',
        },
      },
    },
  },
})

export const themes = {
  light: lightTheme,
  dark: darkTheme,
}
