import React, { useEffect, useState } from 'react'
import {
  Dialog,
  IconButton,
  Typography,
  Box,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  Chip,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { getEvents } from '../services/messages'

const EventBox = styled(Box)(({ theme }) => ({
  width: '500px',
  maxWidth: '500px',
  padding: theme.spacing(2),
  borderRadius: 0,
  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
  outline: 'none',
  position: 'relative',
}))

interface MessageEventsDialogProps {
  open: boolean
  onClose: () => void
  messageId: string
  conversationId: string
  message: string
}

interface Event {
  event: string
  created_at: string
  email: string
}

const MessageEventsDialog: React.FC<MessageEventsDialogProps> = ({
  open,
  onClose,
  messageId,
  conversationId,
  message,
}) => {
  const [events, setEvents] = useState<Event[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (open && conversationId && messageId) {
      setLoading(true)
      setError(null)
      getEvents(conversationId, messageId)
        .then((response) => {
          if (response.success) {
            setEvents(response.data.events)
          } else {
            setError('Failed to load events')
          }
        })
        .catch((err) => {
          setError(err.message)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setError('Failed to load events')
      setLoading(false)
    }
  }, [open, conversationId, messageId])

  if (!open) return null

  const renderEventChips = (email: string) => {
    return events
      .filter((event) => event.email === email)
      .map((event, index) => (
        <Chip
          key={index}
          label={event.event}
          size='small'
          color={event.event === 'dropped' ? 'error' : 'primary'}
          sx={{ marginLeft: '4px' }}
        />
      ))
  }

  const uniqueEmails = Array.from(new Set(events.map((event) => event.email)))

  const content = (
    <>
      <Typography variant='h6' gutterBottom>
        Message Events
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          style={{ outline: 'none', boxShadow: 'none' }}
        >
          <CloseIcon sx={{ outline: 'none', boxShadow: 'none' }} />
        </IconButton>
      </Typography>
      <Typography variant='body1' gutterBottom>
        {message.length > 100 ? (
          <Alert severity='info'>Can't display long message</Alert>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: message }} />
        )}
      </Typography>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity='error'>{error}</Alert>
      ) : events.length > 0 ? (
        <List style={{ marginTop: '20px' }}>
          <Divider />
          {uniqueEmails.map((email, index) => (
            <ListItem
              key={index}
              sx={{ border: '1px solid #f0f0f0', borderRadius: '5px' }}
            >
              <ListItemText
                primary={email}
                secondary={<Box>{renderEventChips(email)}</Box>}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Alert severity='info'>No events available</Alert>
      )}
    </>
  )

  return isMobile ? (
    <Dialog open={open} onClose={onClose} fullScreen>
      {content}
    </Dialog>
  ) : (
    <EventBox>{content}</EventBox>
  )
}

export default MessageEventsDialog
