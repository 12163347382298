import { styled, Theme, CSSObject, useTheme } from '@mui/material/styles'
import {
  Box,
  Button,
  CircularProgress,
  Alert,
  Drawer as MuiDrawer,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Chip,
  SwipeableDrawer,
  // CssBaseline,
  useMediaQuery,
} from '@mui/material'
import {
  Add as AddIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import CreateConvoDialog from '../CreateConversation'
import { getConversations } from '../../services/conversation'
import { useLocalStorage } from '../../hooks/useLocalStorage'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }: { theme: Theme; open: boolean }) => ({
  position: 'inherit',
  width: drawerWidth,
  height: 'calc(100% - 56px)',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

interface Conversation {
  convo_id: string
  name: string
  status: string
  created_at: string
  created_by: string
  is_auto_generated: boolean
  email_message_id?: string
}

export default function MiniDrawer() {
  const { convoId } = useParams<{ convoId: string }>()
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [selectedConvo, setSelectedConvo] = useState<string | null>(
    convoId || null
  )
  const [openCreateConvo, setOpenCreateConvo] = useState(false)
  const [conversations, setConversations] = useState<Conversation[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const [userEmail] = useLocalStorage('userEmail', '')
  const frequency = useMemo(
    () => parseInt(localStorage.getItem('frequency') || '0'),
    []
  )

  const fetchConversations = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const { data } = await getConversations()
      setConversations(data?.conversations)
    } catch (err: any) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchConversations()
    if (frequency !== 0) {
      const interval = setInterval(() => {
        if (!selectedConvo) {
          fetchConversations()
        }
      }, frequency * 1000)

      return () => clearInterval(interval)
    }
  }, [frequency, selectedConvo, fetchConversations])

  useEffect(() => {
    if (convoId) {
      setSelectedConvo(convoId)
    }
  }, [convoId])

  const handleListItemClick = (convo: Conversation) => {
    setSelectedConvo(convo.convo_id)
    navigate(`/conversations/${convo.convo_id}`)
    if (isMobile) {
      setDrawerOpen(false)
    }
  }

  const filteredConversations = useMemo(() => {
    const created = conversations.filter(
      (convo) => convo.created_by === userEmail && !convo.is_auto_generated
    )
    const invited = conversations.filter(
      (convo) => convo.created_by !== userEmail && !convo.is_auto_generated
    )
    const forwarded = conversations.filter(
      (convo) => convo.name.startsWith('Fwd:') || convo.name.startsWith('Fw:')
    )
    const autoGenerated = conversations.filter(
      (convo) => convo.is_auto_generated && !forwarded.includes(convo)
    )

    return { created, invited, forwarded, autoGenerated }
  }, [conversations, userEmail])

  const renderDrawerContent = () => (
    <Box sx={{ width: drawerWidth, mt: 8 }}>
      <Button
        variant='contained'
        startIcon={<AddIcon />}
        sx={{ mt: 2, ml: 2.5, mb: 2.5, mr: 2.5 }}
        onClick={() => setOpenCreateConvo(true)}
      >
        New Conversation
      </Button>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Alert severity='error'>{error}</Alert>
        </Box>
      ) : (
        <>
          {filteredConversations.created.length > 0 && (
            <>
              <Typography sx={{ px: 2.5, mt: 1 }}>Created</Typography>
              <List>
                {filteredConversations.created.map((convo) => (
                  <ListItem
                    key={convo.convo_id}
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      selected={selectedConvo === convo.convo_id}
                      onClick={() => handleListItemClick(convo)}
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                        '&.Mui-selected': {
                          bgcolor: 'primary.main',
                          color: 'white',
                          '&:hover': {
                            bgcolor: 'primary.dark',
                          },
                        },
                        '&:hover': {
                          bgcolor: 'primary.main',
                          color: 'white',
                        },
                      }}
                    >
                      <ListItemText
                        primary={
                          convo.name.length > 20
                            ? `${convo.name.substring(0, 20)}...`
                            : convo.name
                        }
                        sx={{ opacity: 1 }}
                      />
                      <Chip
                        label='created'
                        size='small'
                        sx={{
                          ml: 1,
                          backgroundColor: '#f5f5f5',
                          color: '#333',
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </>
          )}

          {filteredConversations.invited.length > 0 && (
            <>
              <Typography sx={{ px: 2.5, mt: 1 }}>Invited</Typography>
              <List>
                {filteredConversations.invited.map((convo) => (
                  <ListItem
                    key={convo.convo_id}
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      selected={selectedConvo === convo.convo_id}
                      onClick={() => handleListItemClick(convo)}
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                        '&.Mui-selected': {
                          bgcolor: 'primary.main',
                          color: 'white',
                          '&:hover': {
                            bgcolor: 'primary.dark',
                          },
                        },
                        '&:hover': {
                          bgcolor: 'primary.main',
                          color: 'white',
                        },
                      }}
                    >
                      <ListItemText
                        primary={
                          convo.name.length > 20
                            ? `${convo.name.substring(0, 20)}...`
                            : convo.name
                        }
                        sx={{ opacity: 1 }}
                      />
                      <Chip
                        label='invited'
                        size='small'
                        sx={{
                          ml: 1,
                          backgroundColor: '#f5f5f5',
                          color: '#333',
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </>
          )}

          {filteredConversations.autoGenerated.length > 0 && (
            <>
              <Typography sx={{ px: 2.5, mt: 1 }}>Auto Generated</Typography>
              <List>
                {filteredConversations.autoGenerated.map((convo) => (
                  <ListItem
                    key={convo.convo_id}
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      selected={selectedConvo === convo.convo_id}
                      onClick={() => handleListItemClick(convo)}
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                        '&.Mui-selected': {
                          bgcolor: 'primary.main',
                          color: 'white',
                          '&:hover': {
                            bgcolor: 'primary.dark',
                          },
                        },
                        '&:hover': {
                          bgcolor: 'primary.main',
                          color: 'white',
                        },
                      }}
                    >
                      <ListItemText
                        primary={
                          convo.name.length > 20
                            ? `${convo.name.substring(0, 20)}...`
                            : convo.name
                        }
                        sx={{ opacity: 1 }}
                      />
                      <Chip
                        label='auto'
                        size='small'
                        sx={{
                          ml: 1,
                          backgroundColor: '#f5f5f5',
                          color: '#333',
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </>
          )}

          {filteredConversations.forwarded.length > 0 && (
            <>
              <Typography sx={{ px: 2.5, mt: 1 }}>Forwarded</Typography>
              <List>
                {filteredConversations.forwarded.map((convo) => (
                  <ListItem
                    key={convo.convo_id}
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      selected={selectedConvo === convo.convo_id}
                      onClick={() => handleListItemClick(convo)}
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                        '&.Mui-selected': {
                          bgcolor: 'primary.main',
                          color: 'white',
                          '&:hover': {
                            bgcolor: 'primary.dark',
                          },
                        },
                        '&:hover': {
                          bgcolor: 'primary.main',
                          color: 'white',
                        },
                      }}
                    >
                      <ListItemText
                        primary={
                          convo.name.length > 20
                            ? `${convo.name.substring(0, 20)}...`
                            : convo.name
                        }
                        sx={{ opacity: 1 }}
                      />
                      <Chip
                        label='fwd'
                        size='small'
                        sx={{
                          ml: 1,
                          backgroundColor: '#f5f5f5',
                          color: '#333',
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </>
      )}
    </Box>
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {/* <CssBaseline /> */}
      {/* <Appbar /> */}
      {/* Main content container */}
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {isMobile ? (
          <>
            <IconButton
              onClick={() => setDrawerOpen(true)}
              sx={{ position: 'fixed', top: '50%', left: 0, zIndex: 1300 }}
              style={{
                display: drawerOpen ? 'none' : 'block',
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
            <SwipeableDrawer
              variant='temporary'
              open={drawerOpen}
              onOpen={() => setDrawerOpen(true)}
              onClose={() => setDrawerOpen(false)}
            >
              {renderDrawerContent()}
            </SwipeableDrawer>
          </>
        ) : (
          <Drawer variant='permanent' open={true} theme={theme}>
            {renderDrawerContent()}
          </Drawer>
        )}
      </Box>
      <CreateConvoDialog
        open={openCreateConvo}
        onClose={() => setOpenCreateConvo(false)}
      />
    </Box>
  )
}
