import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
// import Typography from "@mui/material/Typography";
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import logo from '../../assets/images/app-logo.png'
import { useNavigate } from 'react-router-dom'
import { lightTheme } from '../../theme'

function Appbar() {
  const navigate = useNavigate()

  return (
    <>
      <AppBar
        position='static'
        sx={{ boxShadow: 0, zIndex: lightTheme.zIndex.drawer + 1 }}
      >
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <img
              src={logo}
              alt='logo'
              style={{ width: '50px', height: '50px', cursor: 'pointer' }}
              onClick={() => navigate('/')}
            />
            {/* <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".1rem",
                textDecoration: "none",
                color: "#fff",
              }}
            >
              INTERNAL CHAT
            </Typography> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title='Open settings'>
                <IconButton onClick={() => navigate('/settings')} sx={{ p: 0 }}>
                  <Avatar alt='H' src='/static/images/avatar/2.jpg' />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}

export default Appbar
