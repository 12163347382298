import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useEffect } from 'react'
import Settings from './pages/settings'
import Login from './pages/login/Login'
import ProtectedRoute from './components/ProtectedRoutes'
import { Amplify, Auth } from 'aws-amplify'
import awsconfig from './config/aws-exports'
import Logout from './pages/Logout'
import Authorize from './pages/login/Authorize'
import NotFoundPage from './pages/404'
import Layout from './components/layout'
import ConvoInterface from './pages/conversation/ConvoInterface'

function App() {
  useEffect(() => {
    Amplify.configure(awsconfig)
    Auth.configure(awsconfig)
  }, [])

  return (
    <Router>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/authorize' element={<Authorize />} />

        {/* Layout-wrapped routes */}
        <Route
          path='/'
          element={
            <ProtectedRoute
              element={
                <Layout>
                  <></>
                </Layout>
              }
            />
          }
        />
        <Route
          path='/conversations/:convoId'
          element={
            <ProtectedRoute
              element={
                <Layout>
                  <ConvoInterface />
                </Layout>
              }
            />
          }
        />
        <Route
          path='/settings'
          element={<ProtectedRoute element={<Settings />} />}
        />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Router>
  )
}

export default App
