import { useEffect, useState } from 'react'
import { Hub } from 'aws-amplify'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { setHttpCookie } from '../../services/authentication'

const Authorize = () => {
  const navigate = useNavigate()
  const [eventRecieved, setEventRecieved] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    const handleAuthEvents = async (capsule: {
      payload: { event: string; data?: any }
    }) => {
      const { event, data } = capsule.payload
      console.log('event', event, data)
      switch (event) {
        case 'signIn':
          console.log(data)
          setEventRecieved(true)
          setIsAuthenticated(true)
          const token = get(data, 'signInUserSession.accessToken.jwtToken')
          const clientId = get(data, 'pool.clientId', null)
          const expiry = get(data, 'signInUserSession.accessToken.payload.exp')

          try {
            const query = `clientId=${clientId}&expiry=${expiry}` // ? is already included
            await setHttpCookie(token, query)
          } catch (error) {
            console.error('Error setting HTTP cookie', error)
            // Optionally handle the error (e.g., show an error message, retry, etc.)
          }
          break

        case 'signOut':
          console.log('user signed out')
          window.location.href = '/login'
          break
        case 'signIn_failure': {
          console.log('user sign in with redirect')
          window.location.href = '/login'
          break
        }

        default:
          break
      }
    }

    Hub.listen('auth', handleAuthEvents)

    return () => {
      Hub.remove('auth', handleAuthEvents)
    }
  }, [eventRecieved, navigate])

  if (isAuthenticated) {
    navigate('/')
  }

  return <div>Authorizing...</div>
}

export default Authorize
