import apiInstance from '../api'
import { isAxiosError } from 'axios'

/*
POST : /c3po/conversations
des : create a new conversation
type : application/json
response : 
{
    "source": "source",
    "data": {
        "message": "Conversation created successfully",
        "conversationId": "b947f9cf-f16e-4d3f-9aa8-86acb77b8f97"
    },
    "success": true
}
*/

interface CreateConversationData {
  name: string
  participants: { channel: string; value: string }[]
}

interface CreateConversationResponse {
  source: string
  data: {
    message: string
    conversationId: string
  }
  success: boolean
}

interface ApiError {
  message: string
  statusCode: number
}

export const createConversation = async (
  data: CreateConversationData
): Promise<CreateConversationResponse> => {
  try {
    const response = await apiInstance.post<CreateConversationResponse>(
      '/c3po/conversations',
      data
    )

    if (response.status !== 201) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    return response.data
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const apiError: ApiError = {
        message: error.response.data.message || 'An error occurred',
        statusCode: error.response.status,
      }
      throw apiError
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}

/*
GET : /c3po/conversations
des : get all conversations of a user
type : application/json
response : 
{
    "source": "ID10-CHAT-MODULE",
    "data": {
        "conversations": [
            {
                "convo_id": "67cd4d94-c849-46b6-9098-66f4b045276e",
                "name": "Test Discussion",
                "status": "active",
                "created_at": "2024-07-12T00:00:06.117Z",
                "created_by": "bcaf5ff2-c436-4bd0-92a2-4b84e54c1139"
            }
        ]
    },
    "success": true
}
*/

interface Conversation {
  convo_id: string
  name: string
  status: string
  created_at: string
  created_by: string
  is_auto_generated: boolean
  email_message_id?: string
}

interface GetConversationsResponse {
  source: string
  data: {
    conversations: Conversation[]
  }
  success: boolean
}

export const getConversations = async (): Promise<GetConversationsResponse> => {
  try {
    const response = await apiInstance.get<GetConversationsResponse>(
      '/c3po/conversations'
    )

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    return response.data
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const apiError: ApiError = {
        message: error.response.data.message || 'An error occurred',
        statusCode: error.response.status,
      }
      throw apiError
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}

/*
PUT : /c3po/conversations/:convo_id
des : update a conversation
type : application/json
req:
{
    "name" : "general",
    "status" : "active"
}
response : 
{
    "source": "ID10-CHAT-MODULE",
    "data": {
        "message": "Conversation updated successfully",
        "conversationId": "b947f9cf-f16e-4d3f-9aa8-86acb77b8f97"
    },
    "success": true
}
*/

interface UpdateConversationData {
  name: string
  status: string
}

interface UpdateConversationResponse {
  source: string
  data: {
    message: string
    conversationId: string
  }
  success: boolean
}

export const updateConversation = async (
  convo_id: string,
  data: UpdateConversationData
): Promise<UpdateConversationResponse> => {
  try {
    const response = await apiInstance.put<UpdateConversationResponse>(
      `/c3po/conversations/${convo_id}`,
      data
    )

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    return response.data
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const apiError: ApiError = {
        message: error.response.data.message || 'An error occurred',
        statusCode: error.response.status,
      }
      throw apiError
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}

/*
PUT : /c3po/conversations/participants/:convo_id
des : add or remove participants to a conversation
type : application/json
req :
{
    "action": "add" | "remove",
    "participants": [
        {
            "channel": "email",
            "value": "hariuc2001@gmail.com"
        }
    ]
}

response : 
{
    "source": "ID10-CHAT-MODULE",
    "data": {
        "message": "Participants added successfully",
        "conversationId": "b947f9cf-f16e-4d3f-9aa8-86acb77b8f97"
    },
    "success": true
}
*/

interface AddRemoveParticipantsData {
  action: string /* "add" | "remove" */
  participants: { channel: string; value: string }[]
}

interface AddRemoveParticipantsResponse {
  source: string
  data: {
    message: string
    conversationId: string
  }
  success: boolean
}

export const addRemoveParticipants = async (
  convo_id: string,
  data: AddRemoveParticipantsData
): Promise<AddRemoveParticipantsResponse> => {
  try {
    const response = await apiInstance.put<AddRemoveParticipantsResponse>(
      `/c3po/conversations/${convo_id}/participants`,
      data
    )

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    return response.data
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const apiError: ApiError = {
        message: error.response.data.message || 'An error occurred',
        statusCode: error.response.status,
      }
      throw apiError
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}

/*
GET : /c3po/conversations/:convo_id
des : get a conversation by id
type : application/json

res :

{
    "source": "ID10-CHAT-MODULE",
    "data": {
        "conversation": {
            "convo_id": "b947f9cf-f16e-4d3f-9aa8-86acb77b8f97",
            "title": "general",
            "participants": [
                "hari.krishnan@trames-engineering.com",
                "goodpack",
                "hf-analytics-test-2864--hf-analytics-test",
                "hariuc2001@gmail.com"
            ],
            "participant_count": "4"
        }
    },
    "success": true
}
*/

interface participant {
  participant_value: string
  participant_channel: string
}

interface GetConversationResponse {
  source: string
  data: {
    conversation: {
      convo_id: string
      title: string
      created_at?: string
      participants: participant[]
      participant_count: string
      is_auto_generated: boolean
      email_message_id?: string
    }
  }
  success: boolean
}

export const getConversation = async (
  convo_id: string
): Promise<GetConversationResponse> => {
  try {
    const response = await apiInstance.get<GetConversationResponse>(
      `/c3po/conversations/${convo_id}`
    )

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    return response.data
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const apiError: ApiError = {
        message: error.response.data.message || 'An error occurred',
        statusCode: error.response.status,
      }
      throw apiError
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}

/*
GET : /c3po/conversations/:convo_id/participants
des : get all participants of a conversation
type : application/json

*/

interface Participant {
  participant_value: string
  participant_channel: string
  participant_id: string
}

interface GetParticipantsResponse {
  source: string
  data: {
    participants: Participant[]
  }
  success: boolean
}

export const getParticipants = async (
  convo_id: string
): Promise<GetParticipantsResponse> => {
  try {
    const response = await apiInstance.get<GetParticipantsResponse>(
      `/c3po/conversations/${convo_id}/participants`
    )

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    return response.data
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const apiError: ApiError = {
        message: error.response.data.message || 'An error occurred',
        statusCode: error.response.status,
      }
      throw apiError
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}

/*
GET: /c3po/conversations/user
des : get all conversations of a user/detail
*/

interface User {
  sub?: string
  name?: string
  email: string
  userStatus: string
}

interface GetUserResponse {
  source: string
  data: {
    user: User
  }
  success: boolean
}

export const getUser = async (): Promise<GetUserResponse> => {
  try {
    const response = await apiInstance.get<GetUserResponse>(
      `/c3po/conversations/user/detail`
    )

    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    return response.data
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const apiError: ApiError = {
        message: error.response.data.message || 'An error occurred',
        statusCode: error.response.status,
      }
      throw apiError
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
}
